<template>
  <div class="filters-form">
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.domain_rating_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="domain_rating_f_from" /></div>
          <div><input-number v-model="domain_rating_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.organic_traffic_f_from }}</div>
        <div class="form-inline">
          <div><input-text v-model="organic_traffic_f_from" /></div>
          <div><input-text v-model="organic_traffic_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.partner_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="partner_status_f"
              @changeValue="changeValuePartner_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.link_builder_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="link_builder_f"
              @changeValue="changeValueLink_builder_f"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.niche_f }}</div>
        <div class="form-inline">
          <div>
            <input-select v-model="niche_f" @changeValue="changeValueNiche_f" />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.sub_niche_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="sub_niche_f"
              @changeValue="changeValueSub_niche_f"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">
          {{ labels.published_links_partner_f_from }}
        </div>
        <div class="form-inline">
          <div><input-number v-model="published_links_partner_f_from" /></div>
          <div><input-number v-model="published_links_partner_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.pending_links_partner_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="pending_links_partner_f_from" /></div>
          <div><input-number v-model="pending_links_partner_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">
          {{ labels.published_links_from_do_f_from }}
        </div>
        <div class="form-inline">
          <div><input-number v-model="published_links_from_do_f_from" /></div>
          <div><input-number v-model="published_links_from_do_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.pending_links_from_do_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="pending_links_from_do_f_from" /></div>
          <div><input-number v-model="pending_links_from_do_f_to" /></div>
        </div>
      </div>
    </div>
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.site_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="site_status_f"
              @changeValue="changeValueSite_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.p2p_f }}</div>
        <div>
          <input-select v-model="p2p_f" @changeValue="changeValueP2p_f" />
        </div>
      </div>
    </div>
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.link_gap_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="link_gap_f_from" /></div>
          <div><input-number v-model="link_gap_f_to" /></div>
        </div>
      </div>
      <div class="form-column"></div>
    </div>
  </div>
</template>

<script>
import InputSelect from '@/components/inputs/InputSelect'
import InputText from '@/components/inputs/InputText'
import InputNumber from '@/components/inputs/InputNumber'
import { fetchData, urls } from '@/utils/urls.js'
import { filtersMixin } from '@/utils/filters-mixin'

export default {
  name: 'SetFiltersSites',
  mixins: [filtersMixin],
  components: { InputSelect, InputText, InputNumber },

  data() {
    return {
      niche_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      sub_niche_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      partner_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },
      domain_rating_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      domain_rating_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      organic_traffic_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'text',
      },

      organic_traffic_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'text',
      },

      published_links_from_do_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      published_links_from_do_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      pending_links_from_do_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_from_do_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      published_links_partner_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      published_links_partner_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      pending_links_partner_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_partner_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      link_builder_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      site_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },
      p2p_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: false, // Показывать возможность фильтрации по строке
        dataList: [
          { id: 'Yes', name: 'Yes' },
          { id: 'No', name: 'No' }, // Данные для выпадающего списка
        ],
      },

      link_gap_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      link_gap_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      labels: {
        site_status_f: 'Site status',
        partner_status_f: 'Partner status',
        link_builder_f: 'Link builder',
        domain_rating_f_from: 'Domain Rating',
        domain_rating_f_to: 'Domain Rating',
        organic_traffic_f_from: 'Organic traffic',
        organic_traffic_f_to: 'Organic traffic',
        published_links_from_do_f_from: 'Published links from DO',
        published_links_from_do_f_to: 'Published links from DO',
        pending_links_from_do_f_from: 'Pending links from DO',
        pending_links_from_do_f_to: 'Pending links from DO',
        published_links_partner_f_from: 'Published links from partner',
        published_links_partner_f_to: 'Published links from partner',
        pending_links_partner_f_from: 'Pending links from Partner',
        pending_links_partner_f_to: 'Pending links from Partner',
        niche_f: 'Niche',
        sub_niche_f: 'Sub-niche',
        p2p_f: 'P2P',
        link_gap_f_from: 'Link Gap',
        link_gap_f_to: 'Link Gap',
      },
    }
  },

  async created() {
    this.fetchDatapartner_status_f()
    this.fetchDataLink_builder_f()
    this.fetchDataNiche()
    this.fetchDataSubNiche()
    this.fetchDataSite_status_f()

    for (let key in this.filters) {
      const element = this.filters[key]
      if (key in this.$data) {
        this.$data[key].value = element
      }
    }
  },

  methods: {
    changeValueSite_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', {
          key: 'site_status_f',
          value: value,
          group: this.labels.site_status_f,
        })
      else
        this.$emit('setFilterValue', {
          key: 'site_status_f',
          value: null,
          group: this.labels.site_status_f,
        })
    },

    changeValueP2p_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'p2p_f', value: value })
      else this.$emit('setFilterValue', { key: 'p2p_f', value: null })
    },

    async fetchDataNiche() {
      let query = `page=1&per_page=all&partner_status=asc`
      let result = await fetchData(urls.niches, 'GET', null, query)

      this.niche_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.niche,
          name: item.niche,
        }
      })
    },

    async fetchDataSubNiche() {
      let result = await fetchData(
        urls.getSubNiches,
        'GET',
        null,
        `page=1&per_page=all&sub_niche=asc`
      )

      this.sub_niche_f.dataList = (result?.sub_niches || []).map((item) => {
        return {
          id: item.sub_niche,
          name: item.sub_niche,
        }
      })
    },

    changeValueNiche_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'niche_f', value: value })
      else {
        this.$emit('setFilterValue', { key: 'niche_f', value: null })
      }
    },

    changeValueSub_niche_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'sub_niche_f', value: value })
      else this.$emit('setFilterValue', { key: 'sub_niche_f', value: null })
    },

    changeValuePartner_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'partner_status_f', value: value })
      else
        this.$emit('setFilterValue', { key: 'partner_status_f', value: null })
    },

    changeValueLink_builder_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'link_builder_f', value: value })
      else this.$emit('setFilterValue', { key: 'link_builder_f', value: null })
    },

    async fetchDatapartner_status_f() {
      let query = `page=1&per_page=all&partner_status=asc`
      let result = await fetchData(urls.partnerStatuses, 'GET', null, query)

      this.partner_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.partner_status,
          name: item.partner_status,
        }
      })
    },

    async fetchDataSite_status_f() {
      let query = `page=1&per_page=all&site_status=asc`
      let result = await fetchData(urls.siteStatuses, 'GET', null, query)

      this.site_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.site_status,
          name: item.site_status,
        }
      })
    },

    async fetchDataLink_builder_f() {
      let query = `page=1&per_page=all&email=asc`
      let result = await fetchData(urls.users, 'GET', null, query)

      this.link_builder_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.email,
          name: item.email,
        }
      })
    },
  },
}
</script>
