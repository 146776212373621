<template>
  <div :class="{ 'colapsed-header': isColapsedBlock }">
    <colapse-button
      :isColapsedBlock="isColapsedBlock"
      @click="isColapsedBlock = !isColapsedBlock"
    />
    <div
      v-if="!isColapsedBlock"
      class="head-and-filters js-head-and-filters"
      :style="`right: ` + rightHeadPosition"
    >
      <header-page :title="'Sites'" :hasBackLink="false" />

      <div class="filters-container">
        <div class="filters-block">
          <div class="search">
            <input-text v-model="search_text" />
            <div
              v-if="isShowClear"
              class="main-button lg ml-15"
              @click="clearFilters"
            >
              <div>Clear</div>
            </div>
          </div>
          <div class="button">
            <div class="main-button lg orange" @click="isVisibleFilters = true">
              <div class="icon" v-html="Filter"></div>
              <div>Filters</div>
            </div>
          </div>
        </div>
        <div class="selected-filters" v-if="selectedFilters.length">
          <div
            class="selected-filters-groups"
            v-for="(group, key) in filterGroups"
            :key="key"
          >
            <div class="selected-filters-groups-item">
              <div
                class="icon"
                @click="removeFilterGroup(key)"
                v-html="Cross"
              ></div>
              <div class="text">{{ group[0].groupName }}</div>
              <div class="selected-filters-groups-item-sub-items">
                <template v-for="groupItem in group" :key="groupItem.text">
                  <div
                    v-if="!isArray(groupItem.text)"
                    class="selected-filters-item"
                  >
                    <div
                      class="icon"
                      @click="removeFilter(groupItem.id)"
                      v-html="Cross"
                    ></div>
                    <div class="text">
                      {{ groupItem.label }} {{ groupItem.text }}
                    </div>
                  </div>

                  <div
                    v-else
                    class="selected-filters-item"
                    v-for="groupItemArray in groupItem.text"
                    :key="groupItemArray"
                  >
                    <div
                      class="icon"
                      @click="
                        removeFilterArrayItem(groupItem.id, groupItemArray)
                      "
                      v-html="Cross"
                    ></div>
                    <div class="text">{{ groupItemArray }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model:selectedRows="selectedRows"
      v-model="rows"
      v-model:options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewPartnerSites"
      @saveRow="savePartnerSites"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @setTab="setTab"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      @changedColumnsParams="changedColumnsParams"
      @importFile="uploadFile"
    />
    <set-filters
      v-if="isVisibleFilters"
      v-model="isVisibleFilters"
      :setFilterComponent="setFilterComponent"
      :filters="filters"
      @setFilters="setFilters"
    />
    <alert-yes-no
      v-if="isVisibleExportAlert"
      title="Warning"
      text="The limit of symbols reached. Please decrease the amount of links"
      :alertResult="alertExportAlertResult"
      :hideYes="false"
      :hideNo="true"
    />
  </div>

  <alert-yes-no
    v-if="isVisibleUploadAlert"
    :title="contentUploadTitle"
    :text="contentUploadResult"
    :alertResult="alertUploadResult"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import { shallowRef } from 'vue'
import NewRowSites from '@/components/NewRows/NewRowSites'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import SetFilterComponent from '@/components/partner/SetFilters/SetFiltersSites'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions, GridTitleButton } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'
import { Import } from '@/utils/icons'

export default {
  name: 'Sites',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    let filterKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        titleTableButtons: [
          new GridTitleButton({
            id: 0,
            image: Import,
            text: 'Backlinks',
            isVisible: hasAccess(permissions.ahrefs_upload_links),
            onClick: this.uploadFileBacklinks,
            isFile: true,
            buttonClass: '',
            imageClass: 'import',
          }),

          new GridTitleButton({
            id: 1,
            image: Import,
            text: 'Ahrefs',
            isVisible: hasAccess(permissions.get_ahrefs_sites),
            onClick: this.uploadFileAhrefs,
            isFile: true,
            buttonClass: '',
            imageClass: 'import',
          }),
        ],

        isImport: hasAccess(permissions.import_sites_from_csv),
        importButtonText: 'Import',
        addButtonText: 'Add site',
        isSetColumns: true,
        isEditable: hasAccess(permissions.update_site),
        isDelete: hasAccess(permissions.delete_site),
        isExportToCSV: hasAccess(permissions.export_sites),
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">
            Are you sure you want to delete site${rows.length > 1 ? 's' : ''}?
              ${rows
                .map(
                  (item) =>
                    `<div>• ${item.company_name}, ${item.link_builder}, ${
                      item.url
                    }, ${item.site_status || ''}</div>`
                )
                .join('')}
            </div>`
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'id',
          text: 'Site ID',
          positionDefault: colsKeyUrlIndex,
          width: '80px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'partner_id',
          text: 'Partner ID',
          positionDefault: colsKeyUrlIndex,
          width: '80px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'company_name',
          text: 'Company name',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row }) => {
            if (value)
              return `<a href="${
                window.location.origin +
                '/link-builder-partner/' +
                row.partner_id
              }" target="_blank">${value}</a>`
            return value
          },
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'email',
          text: 'Company email',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="mailto:${value}" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'partner_status',
          text: 'Partner status',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_builder',
          text: 'Link builder',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'url',
          text: 'Domain',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="${
                value.includes('http') ? value : '//' + value
              }" title="${value}" target="_blank">${value}</a>`
            return ''
          },
          width: '300px',
          cssClass: 'bold-underline hand',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_gap',
          text: 'Link Gap',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'site_status',
          text: 'Site status',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'niche',
          text: 'Niche',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'sub_niche',
          text: 'Sub-niche',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            if (!value) return ''

            if (value?.length)
              return value.map((item) => item?.sub_niche || item).join(', ')

            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'commercial_pages',
          text: 'Commercial pages',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'domain_rating',
          text: 'Domain Rating',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'organic_traffic',
          text: 'Organic traffic',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'main_traffic_source',
          text: 'Main traffic source',
          positionDefault: colsKeyUrlIndex,
          width: '75px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'organic_keywords',
          text: 'Organic keywords',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'p2p',
          text: 'P2P',
          positionDefault: colsKeyUrlIndex,
          width: '75px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'pending_links_from_do',
          text: 'Pending links from DO',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'pending_links_partner',
          text: 'Pending links from Partner',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'published_links_from_do',
          text: 'Published links from DO',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'published_links_partner',
          text: 'Published links from partner',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'created_by',
          text: 'Created by',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'modified_by',
          text: 'Last edited by',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),
      ],

      newPartnerSites: {
        partner_id: null,
        url: null,
        niche: null,
        sub_niche: null,
        commercial_pages: null,
        domain_rating: null,
        organic_traffic: null,
        organic_keywords: null,
        site_status: null,
        p2p: null,
      },

      newRowComponent: shallowRef(NewRowSites),
      listKey: 'sites',
      setFilterComponent: shallowRef(SetFilterComponent),
      groupToFilter: {
        site_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'site_status_f',
          name: 'Site status',
        },
        domain_rating_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'domain_rating_f',
          name: 'Domain Rating',
        },
        domain_rating_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'domain_rating_f',
          name: 'Domain Rating',
        },
        organic_traffic_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'organic_traffic_f',
          name: 'Organic traffic',
        },
        organic_traffic_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'organic_traffic_f',
          name: 'Organic traffic',
        },
        partner_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'partner_status_f',
          name: 'Partner status',
        },
        link_builder_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_builder_f',
          name: 'Link builder',
        },
        published_links_from_do_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_from_do_f',
          name: 'Published links from DO',
        },
        published_links_from_do_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_from_do_f',
          name: 'Published links from DO',
        },
        pending_links_from_do_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_from_do_f',
          name: 'Pending links from DO',
        },
        pending_links_from_do_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_from_do_f',
          name: 'Pending links from DO',
        },
        published_links_partner_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_partner_f',
          name: 'Published links from partner',
        },
        published_links_partner_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_partner_f',
          name: 'Published links from partner',
        },
        pending_links_partner_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_partner_f',
          name: 'Pending links from Partner',
        },
        pending_links_partner_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_partner_f',
          name: 'Pending links from Partner',
        },
        link_gap_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_gap_f',
          name: 'Link Gap',
        },
        link_gap_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_gap_f',
          name: 'Link Gap',
        },
        niche_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'niche_f',
          name: 'Niche',
        },
        sub_niche_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'sub_niche_f',
          name: 'Sub-niche',
        },
        p2p_f: { keyUrl: 'f' + filterKeyUrlIndex++, key: 'p2p_f', name: 'P2P' },
      },
      isVisibleExportAlert: false,
      contentUploadResult: '',
      isVisibleUploadAlert: false,
      contentUploadTitle: 'Result',
    }
  },

  created() {
    this.setDefaultColumnsParams() // 1
    this.setMetaDataColumnsParams() // 2
    this.setDataFromQueryParams() // 3
    this.fetchData()
  },

  methods: {
    alertUploadResult(/*result*/) {
      this.isVisibleUploadAlert = false
      this.contentUploadResult = ''
    },

    async uploadFileBacklinks(refFile) {
      try {
        let file = refFile.files[0]

        this.$store.commit('setIsLoading', true)
        let formData = new FormData()
        formData.append('file', file)
        const token = localStorage.getItem('userAuth')
        let requestData = {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).access_token}`,
          },
        }

        const response = await fetch(urls.ahrefsLinksUpload, requestData)
        let result = await response.json()
        this.$store.commit('setIsLoading', false)

        this.contentUploadResult = `
          <h3>${result.message}</h3>
          <a href="${result?.link}" target="_blank">Download file</a>
          `
        this.contentUploadTitle = 'Result'
        this.isVisibleUploadAlert = true

        refFile.value = null
      } catch (e) {
        this.$notify({
          text: e,
          type: 'error',
          duration: 2000,
        })
        this.$store.commit('setIsLoading', false)
        refFile.value = null
      }
    },

    async uploadFileAhrefs(refFile) {
      try {
        let file = refFile.files[0]

        this.$store.commit('setIsLoading', true)
        let formData = new FormData()
        formData.append('file', file)
        const token = localStorage.getItem('userAuth')
        let requestData = {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).access_token}`,
          },
        }

        const response = await fetch(urls.importAhrefs, requestData)
        let result = await response.json()
        this.$store.commit('setIsLoading', false)

        this.contentUploadResult = `
          <h3>${result.message}</h3>
          `
        this.contentUploadResult += result?.link
          ? `<a href="${result?.link}" target="_blank">Download file</a>`
          : ''

        this.contentUploadTitle = 'Status Update'
        this.isVisibleUploadAlert = true

        refFile.value = null
      } catch (e) {
        this.$notify({
          text: e,
          type: 'error',
          duration: 2000,
        })
        this.$store.commit('setIsLoading', false)
        refFile.value = null
      }
    },

    async uploadFile(refFile) {
      try {
        let file = refFile.files[0]

        this.$store.commit('setIsLoading', true)
        let formData = new FormData()
        formData.append('file', file)
        const token = localStorage.getItem('userAuth')
        let requestData = {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).access_token}`,
          },
        }

        const response = await fetch(urls.importSites, requestData)
        let result = await response.json()
        this.$store.commit('setIsLoading', false)

        if (result?.detail) {
          if (typeof result?.detail === 'string') {
            this.$notify({
              text: result?.detail,
              type: 'error',
              duration: 2000,
            })
          }
        } else {
          let arrayToDottedString = (arr) => {
            if (!arr || !arr.length) return ''

            return arr
              .map((item) => {
                return `<div>• ${item}</div>`
              })
              .join('')
          }

          let sites_good = arrayToDottedString(result?.sites_good)
          let sites_bad = arrayToDottedString(result?.sites_bad)
          let errors = arrayToDottedString(result?.errors)
          if (result?.template_link)
            errors += `<div><a href="${result?.template_link}" target="_blank">link to template</a></div>`

          this.contentUploadResult = `
               ${
                 sites_good
                   ? '<h3>Link From DO <b style="color:green">(Good)</b></h3>'
                   : ''
               }
               ${sites_good}

               ${
                 sites_bad
                   ? '<h3 class="mt-20">Link From DO <b style="color:red">(Bad)</b></h3>'
                   : ''
               }
               ${sites_bad}

               ${errors ? '<h3>Errors</h3>' : ''}
               ${errors}
               `
          this.contentUploadTitle = 'Result'
          this.isVisibleUploadAlert = true
        }

        refFile.value = null
      } catch (e) {
        this.$notify({
          text: e,
          type: 'error',
          duration: 2000,
        })
        this.$store.commit('setIsLoading', false)
        refFile.value = null
      }
    },

    alertExportAlertResult() {
      this.isVisibleExportAlert = false
    },

    setTab(id) {
      if (!id) return

      this.activeTab = id
      this.activateTab(id)
      this.$emit('setTab', id)
    },

    saveNewPartnerSites(partnerSites) {
      this.setNewPartnerSites(partnerSites)
      this.addRow()
    },

    savePartnerSites(partnerSites) {
      this.editRow(partnerSites)
    },

    newPartnerSitesToDefault() {
      for (let key in this.newPartnerSites) {
        this.newPartnerSites[key] = null
      }
    },

    setNewPartnerSites(partnerSites) {
      for (let key in partnerSites) {
        this.newPartnerSites[key] = partnerSites[key]
      }
    },

    async editRow(partnerSites) {
      this.$store.commit('setIsLoading', true)
      const id = partnerSites.id
      delete partnerSites.id

      let result = await fetchData(
        urls.partnerSites + `/${id}`,
        'POST',
        this.bodyToSave(partnerSites)
      )
      if (!result?.detail) {
        let partnerSitesRow = this.rows.find((item) => item.id === id)
        for (const key in partnerSites) {
          if (key in partnerSitesRow) partnerSitesRow[key] = partnerSites[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.sites,
        'POST',
        this.bodyToSave(this.newPartnerSites)
      )
      if (!result?.detail) {
        const tmpPartnerSites = this.newPartnerSites
        this.rows.unshift({ ...{ id: result.id }, ...tmpPartnerSites })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newPartnerSitesToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids = []) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ''
        for (const id of ids) {
          query += `site_ids=${id}&`
        }

        return query
      }

      let query = ''

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += `${this.queryToFetch(1, 'all')}&${this.queryToFilters()}&${
          this.queryToSort() & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(urls.sites, 'DELETE', null, query)
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = '&'
        for (const id of ids) {
          query += `sites_ids=${id}&`
        }

        if (query === '&') {
          for (const row of this.rows) {
            query += `sites_ids=${row.id}&`
          }
        }

        if (this.options.isShowAllColumns) {
          query += `all_columns=true&`
        } else {
          query += `all_columns=false&`
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      if (query.length > 30000) {
        console.warn('query length', query.length)
        this.isVisibleExportAlert = true
        this.$store.commit('setIsLoading', false)
        return
      }

      await fetchCSV(urls.sitesToCsv, 'GET', 'sites', null, query)
      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      this.$nextTick(() => {
        this.setHeightGridScrolableBlock()
      })

      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = ''
      let prevSearch_text = this.search_text.value

      query = `${this.queryToFetch()}&${this.queryToFilters()}&${this.queryToSort()}`
      let result = await fetchData(urls.sites, 'GET', null, query)

      this.rows = result?.rows || []
      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
